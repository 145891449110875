import logo from "../../../assets/png/logo.png";

import styles from "./navbar.module.scss";

export const Navbar = () => {
  return (
    <nav className={styles.navbar}>
      <a
        href="https://ziroh.com"
        target="_blank"
        rel="noopener noreferrer"
        aria-label="ziroh labs"
      >
        <img
          src={logo}
          className={styles.logo}
          alt="Ziroh labs"
          height={26}
          width={128}
        />
      </a>
    </nav>
  );
};
